import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Box,
  Divider,
  Paper,
  Tooltip,
  Alert,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import WundaButton from './WundaButton';
import CheckIcon from '@mui/icons-material/Check';
import GenericSnackbar from '../snackbar/GenericSnackbar';
import { apiRequest } from '../../api/api';
import HistoryIcon from '@mui/icons-material/History';
import { format } from 'date-fns';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  fontWeight: 'bold',
  padding: '20px',
}));

const EXPIRATION_OPTIONS = [
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' },
  { value: 0, label: 'Never expires' },
];

const WundaShareDialog = ({
  open,
  onClose,
  title = 'Share',
  showEmailShare = false,
  showLinkShare = false,
  linkShareProps = {},
  emailShareProps = {},
  isLoading = false,
  onEmailShare,
  onGenerateLink,
  currentLink = '',
  disabled = false,
  disabledMessage,
  error = '',
  confirmEmailShare = true,
  confirmEmailPrompt = 'Are you sure you want to share to {email}?',
  shareTokenExpiresAt = null,
  confirmUnsharePrompt = 'Are you sure you want to disable sharing? This will make the current share link unusable.',
}) => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmNewLinkOpen, setConfirmNewLinkOpen] = useState(false);
  const [pendingExpirationDays, setPendingExpirationDays] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [confirmUnshareOpen, setConfirmUnshareOpen] = useState(false);
  const [showShareHistory, setShowShareHistory] = useState(false);
  const [shareHistory, setShareHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);

  const getRemainingDays = (expiresAt) => {
    if (!expiresAt) return null;
    const now = new Date();
    const expiry = new Date(expiresAt);
    const diffTime = expiry - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  // Get current expiration days and check if it's a custom value
  const currentRemainingDays = shareTokenExpiresAt ? getRemainingDays(shareTokenExpiresAt) : null;
  const isCustomDuration =
    currentRemainingDays !== null && !EXPIRATION_OPTIONS.some((option) => option.value === currentRemainingDays);

  // Create dynamic options list including custom duration if needed
  const currentOptions =
    isCustomDuration && currentRemainingDays > 0
      ? [{ value: currentRemainingDays, label: `${currentRemainingDays} days (current)` }, ...EXPIRATION_OPTIONS]
      : EXPIRATION_OPTIONS;

  const [expirationDays, setExpirationDays] = useState(() => {
    if (currentLink) {
      if (shareTokenExpiresAt === null) return 0;
      return getRemainingDays(shareTokenExpiresAt);
    }
    return 7;
  });

  useEffect(() => {
    if (currentLink) {
      if (shareTokenExpiresAt === null) {
        setExpirationDays(0);
      } else {
        setExpirationDays(getRemainingDays(shareTokenExpiresAt));
      }
    }
  }, [currentLink, shareTokenExpiresAt]);

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsEmailValid(validateEmail(emailInput));
  };

  const handleExpirationChange = (event) => {
    const newValue = Number(event.target.value);
    setExpirationDays(newValue);
  };

  const handleEmailShare = () => {
    if (isEmailValid && email) {
      if (emailShareProps?.onShare) {
        emailShareProps.onShare(email, subject, body);
      } else if (onEmailShare) {
        onEmailShare(email, subject, body);
      }
      setEmail('');
      setSubject('');
      setBody('');
    }
  };

  const handleEmailShareClick = () => {
    if (isEmailValid && email) {
      if (!confirmEmailShare) {
        handleEmailShare();
      } else {
        setConfirmOpen(true);
      }
    }
  };

  const handleGenerateLinkClick = () => {
    if (currentLink) {
      // Only show confirmation dialog if replacing existing link
      setConfirmNewLinkOpen(true);
      setPendingExpirationDays(expirationDays);
    } else {
      // Direct generation if no existing link
      onGenerateLink(expirationDays);
      setCopySuccess(false);
    }
  };

  const handleConfirmNewLink = () => {
    if (pendingExpirationDays !== null) {
      onGenerateLink(pendingExpirationDays);
    }
    setConfirmNewLinkOpen(false);
    setPendingExpirationDays(null);
    // Reset copy success state when generating new link
    setCopySuccess(false);
  };

  const handleCancelNewLink = () => {
    setConfirmNewLinkOpen(false);
    setPendingExpirationDays(null);
    // Reset expiration days to current value if there's a link
    if (currentLink) {
      if (shareTokenExpiresAt === null) {
        setExpirationDays(0);
      } else {
        setExpirationDays(getRemainingDays(shareTokenExpiresAt));
      }
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmYes = () => {
    handleEmailShare();
    setConfirmOpen(false);
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleCopyLink = () => {
    if (currentLink) {
      navigator.clipboard.writeText(currentLink);
      setCopySuccess(true);
      showSuccess('Link copied to clipboard');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  const handleUnshareClick = () => {
    setConfirmUnshareOpen(true);
  };

  const handleUnshareConfirmClose = () => {
    setConfirmUnshareOpen(false);
  };

  const handleUnshareConfirm = () => {
    if (linkShareProps.onDisableShare) {
      linkShareProps.onDisableShare();
    }
    setConfirmUnshareOpen(false);
  };

  const fetchShareHistory = async () => {
    if (!showShareHistory) {
      setLoadingHistory(true);
      try {
        const response = await apiRequest('GET', `/insights-report/${emailShareProps.reportId}/share-history`);
        setShareHistory(response);
      } catch (error) {
        console.error('Error fetching share history:', error);
        setSnackbar({
          open: true,
          message: 'Failed to load share history',
          severity: 'error',
        });
      } finally {
        setLoadingHistory(false);
      }
    }
    setShowShareHistory(!showShareHistory);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
          },
        }}
      >
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {disabled && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {disabledMessage}
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {showLinkShare && (
            <Box sx={{ mb: showEmailShare ? 3 : 0 }}>
              <Box display="flex" alignItems="center" gap={1} mb={1}>
                <LinkIcon color="primary" />
                <Typography variant="subtitle1">Share via link</Typography>
              </Box>
              <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                {linkShareProps.isExpired && (
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    {linkShareProps.expiredMessage || 'This share link has expired. Please generate a new link.'}
                  </Alert>
                )}
                {currentLink && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <TextField
                      fullWidth
                      value={currentLink}
                      placeholder={isLoading ? 'Generating link...' : 'No link generated yet'}
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={isLoading || linkShareProps.isExpired}
                    />
                    <span>
                      <Tooltip title={linkShareProps.isExpired ? 'Link expired' : 'Copy link'}>
                        <span>
                          <IconButton
                            onClick={handleCopyLink}
                            disabled={!currentLink || isLoading || linkShareProps.isExpired}
                          >
                            {copySuccess ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                          </IconButton>
                        </span>
                      </Tooltip>
                    </span>
                  </Box>
                )}
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                  <FormControl fullWidth size="small" margin="dense" sx={{ mb: 0 }}>
                    <InputLabel id="expiration-select-label">Link Expiration</InputLabel>
                    <Select
                      labelId="expiration-select-label"
                      id="expiration-select"
                      value={expirationDays}
                      label="Link Expiration"
                      onChange={handleExpirationChange}
                      disabled={isLoading}
                    >
                      {currentOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    {linkShareProps.showDisableShare && !linkShareProps.isExpired && (
                      <Box>
                        <WundaButton
                          size="small"
                          onClick={handleUnshareClick}
                          disabled={isLoading}
                          color="error"
                          variant="outlined"
                          startIcon={<LinkOffIcon />}
                        >
                          {linkShareProps.disableShareLabel || 'Disable Sharing'}
                        </WundaButton>
                      </Box>
                    )}
                    <Box>
                      {!currentLink ? (
                        <WundaButton
                          size="small"
                          onClick={handleGenerateLinkClick}
                          disabled={isLoading}
                          startIcon={<LinkIcon />}
                          variant="contained"
                        >
                          Generate Link
                        </WundaButton>
                      ) : (
                        <WundaButton
                          size="small"
                          onClick={handleGenerateLinkClick}
                          disabled={isLoading}
                          startIcon={<LinkIcon />}
                        >
                          Generate New Link
                        </WundaButton>
                      )}
                    </Box>
                  </Box>
                  {currentLink && (
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                      {expirationDays === 0
                        ? 'This link will never expire'
                        : `This link will expire in ${expirationDays} days`}
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Box>
          )}

          {showEmailShare && (
            <>
              {showLinkShare && <Divider sx={{ my: 2 }} />}
              <Box sx={{ mt: 2 }}>
                <Box display="flex" alignItems="center" gap={1} mb={1}>
                  <EmailIcon color="primary" />
                  <Typography variant="subtitle1">Share via email</Typography>
                </Box>
                <Paper variant="outlined" sx={{ p: 2 }}>
                  <TextField
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    error={!isEmailValid}
                    helperText={!isEmailValid && 'Please enter a valid email'}
                    className="fs-mask"
                  />
                  {emailShareProps.showSubject && (
                    <TextField
                      margin="dense"
                      id="subject"
                      label="Subject"
                      placeholder={emailShareProps.subjectPlaceholder}
                      type="text"
                      fullWidth
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="fs-mask"
                    />
                  )}
                  {emailShareProps.showBody && (
                    <TextField
                      margin="dense"
                      id="body"
                      label="Your message"
                      placeholder={emailShareProps.bodyPlaceholder}
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                      className="fs-mask"
                    />
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 1, gap: 2 }}>
                    {emailShareProps.shareHistoryCount > 0 && (
                      <Typography
                        variant="caption"
                        sx={{
                          color: 'primary.main',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                          '&:hover': { textDecoration: 'underline' },
                          marginRight: 'auto',
                        }}
                        onClick={fetchShareHistory}
                      >
                        <HistoryIcon fontSize="small" />
                        {showShareHistory ? 'Hide history' : 'View history'}
                      </Typography>
                    )}
                    <WundaButton
                      size="small"
                      onClick={handleEmailShareClick}
                      variant="contained"
                      color="primary"
                      disabled={disabled || isLoading || !isEmailValid || !email}
                      startIcon={<EmailIcon />}
                    >
                      Send Email
                    </WundaButton>
                  </Box>
                  {showShareHistory && (
                    <Box sx={{ mt: 2, maxHeight: 200, overflowY: 'auto' }}>
                      {loadingHistory ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                          <CircularProgress size={20} />
                        </Box>
                      ) : shareHistory.length > 0 ? (
                        <List dense disablePadding>
                          {shareHistory.map((item, index) => (
                            <ListItem
                              key={index}
                              sx={{
                                py: 0.5,
                                borderBottom: index !== shareHistory.length - 1 ? '1px solid' : 'none',
                                borderColor: 'divider',
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Typography variant="body2" className="fs-mask">
                                    {item.recipient}
                                  </Typography>
                                }
                                secondary={
                                  <Typography variant="caption" color="text.secondary">
                                    {format(new Date(item.sharedAt), 'MMM d, yyyy HH:mm')}
                                    {item.type === 'link' && ' (via link)'}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', py: 1 }}>
                          No share history available
                        </Typography>
                      )}
                    </Box>
                  )}
                </Paper>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <WundaButton onClick={onClose} variant="outlined" color="primary">
                Done
              </WundaButton>
            </Grid>
          </Grid>
        </DialogActions>
        {confirmEmailShare && (
          <Dialog open={confirmOpen} onClose={handleConfirmClose}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText className="fs-mask">{confirmEmailPrompt.replace('{email}', email)}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <WundaButton onClick={handleConfirmClose} color="primary" variant="outlined">
                No
              </WundaButton>
              <WundaButton onClick={handleConfirmYes} color="primary" variant="contained" autoFocus>
                Yes
              </WundaButton>
            </DialogActions>
          </Dialog>
        )}
        <Dialog open={confirmNewLinkOpen} onClose={handleCancelNewLink}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to generate a new link? The current share link will become unusable.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <WundaButton onClick={handleCancelNewLink} color="primary" variant="outlined">
              No
            </WundaButton>
            <WundaButton onClick={handleConfirmNewLink} color="primary" variant="contained" autoFocus>
              Yes
            </WundaButton>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmUnshareOpen} onClose={handleUnshareConfirmClose}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>{confirmUnsharePrompt}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <WundaButton onClick={handleUnshareConfirmClose} variant="outlined" color="primary">
              No
            </WundaButton>
            <WundaButton onClick={handleUnshareConfirm} variant="contained" color="error" autoFocus>
              Yes, Disable Sharing
            </WundaButton>
          </DialogActions>
        </Dialog>
      </Dialog>

      <GenericSnackbar open={snackbar} autoHideDuration={6000} onClose={handleSnackbarClose} />
    </>
  );
};

WundaShareDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  showEmailShare: PropTypes.bool,
  showLinkShare: PropTypes.bool,
  linkShareProps: PropTypes.shape({
    onDisableShare: PropTypes.func,
    showDisableShare: PropTypes.bool,
    infoList: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.node,
        primary: PropTypes.string,
        secondary: PropTypes.string,
      })
    ),
    generateNewLinkLabel: PropTypes.string,
    disableShareLabel: PropTypes.string,
    isExpired: PropTypes.bool,
    expiredMessage: PropTypes.string,
    expirationDays: PropTypes.number,
  }),
  emailShareProps: PropTypes.shape({
    reportId: PropTypes.string,
    shareHistoryCount: PropTypes.number,
    showSubject: PropTypes.bool,
    showBody: PropTypes.bool,
    subjectPlaceholder: PropTypes.string,
    bodyPlaceholder: PropTypes.string,
    onShare: PropTypes.func,
  }),
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onEmailShare: PropTypes.func,
  onGenerateLink: PropTypes.func,
  currentLink: PropTypes.string,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  confirmEmailShare: PropTypes.bool,
  confirmEmailPrompt: PropTypes.string,
  shareTokenExpiresAt: PropTypes.string,
  confirmUnsharePrompt: PropTypes.string,
};

export default WundaShareDialog;
