import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import WundaButton from './WundaButton';
import Iconify from '../iconify';

const WundaTranscriptPreview = ({
  open,
  onClose,
  onGoToTranscript,
  selectedDialogue,
  roleDialogue,
  onTimeSelect = () => {},
  handleOpenTranscriptCard = () => {},
}) => {
  const theme = useTheme();
  const dialogueRefs = useRef([]);
  const [scrollIndex, setScrollIndex] = useState(null);

  useEffect(() => {
    if (open && selectedDialogue && roleDialogue) {
      const index = roleDialogue.findIndex(
        (dialogueItem) =>
          dialogueItem.time === selectedDialogue.time || dialogueItem.startTime === selectedDialogue.startTime
      );
      setScrollIndex(index);
    }
  }, [open, selectedDialogue, roleDialogue]);

  useLayoutEffect(() => {
    if (open && scrollIndex !== null && dialogueRefs.current[scrollIndex]) {
      const selectedElement = dialogueRefs.current[scrollIndex];
      setTimeout(() => {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
      }, 0);
    }
  }, [open, scrollIndex]);

  const handleClose = () => {
    setScrollIndex(null);
    onClose();
  };

  const handleGoToTranscript = () => {
    if (onTimeSelect) {
      onTimeSelect(selectedDialogue);
    }
    handleOpenTranscriptCard();
    onGoToTranscript();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Transcript Preview
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ overflow: 'auto', maxHeight: '80vh' }}>
          {roleDialogue?.map((dialogue, index) => (
            <Box
              key={index}
              ref={(el) => {
                dialogueRefs.current[index] = el;
              }}
              sx={{
                mb: 2,
                p: 1,
                borderRadius: 1,
                bgcolor:
                  dialogue.time === selectedDialogue?.time || dialogue.startTime === selectedDialogue?.startTime
                    ? theme.palette.secondary.main
                    : 'transparent',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" color="text.primary">
                  {dialogue.speaker.charAt(0).toUpperCase() + dialogue.speaker.slice(1)}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
                  ({dialogue.startTime ? new Date(dialogue.startTime * 1000).toISOString().slice(11, 19) : ''} -
                  {dialogue.endTime ? new Date(dialogue.endTime * 1000).toISOString().slice(11, 19) : ''})
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {dialogue.text}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <WundaButton
            onClick={handleGoToTranscript}
            startIcon={<Iconify icon="eva:file-text-outline" />}
            color="primary"
            variant="outlined"
          >
            Go To Transcript
          </WundaButton>
          <WundaButton onClick={handleClose} color="inherit">
            Close
          </WundaButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

WundaTranscriptPreview.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGoToTranscript: PropTypes.func.isRequired,
  selectedDialogue: PropTypes.object,
  roleDialogue: PropTypes.array,
  onTimeSelect: PropTypes.func,
  handleOpenTranscriptCard: PropTypes.func,
};

export default WundaTranscriptPreview;
