import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Alert, CircularProgress, Box, Typography, Card } from '@mui/material';
import { apiRequest } from '../../api/api';
import InsightsReportCard from './InsightsReportCard';
import InsightsIcon from '@mui/icons-material/Insights';
import SessionHeader from './SessionHeader';

const SharedInsightsReportView = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [report, setReport] = useState(null);
  const [sessionData, setSessionData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSharedReport = async () => {
      try {
        const response = await apiRequest('GET', `/insights-report/shared/${token}`);
        if (response && response.insightsReports?.length > 0) {
          setReport(response.insightsReports[0]);
          setSessionData(response);
        } else {
          throw new Error('No report data received');
        }
      } catch (error) {
        console.error('Error fetching shared report:', error);
        setError(error.message || 'Failed to load shared report');
        if (error.response?.status === 404) {
          navigate('/404');
        }
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchSharedReport();
    }
  }, [token, navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 5 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!sessionData || !report) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Typography variant="h6">No session data available</Typography>
      </Box>
    );
  }

  return (
    <Container component="main">
      <SessionHeader
        sessionData={sessionData}
        isSharedView={true}
        showShareControls={false}
        handleOpenListenCard={() => {}}
        handleOpenTranscriptCard={() => {}}
        handleOpenInsightsCard={() => {}}
        openCard="insights"
        language={sessionData.language}
        showCards={false}
      />

      <Card sx={{ mt: 2 }}>
        <Box sx={{ p: 2 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <InsightsIcon sx={{ fontSize: 40, mr: 2 }} />
            <Typography variant="h6">Insights Report</Typography>
          </Box>
          {report && (
            <InsightsReportCard
              report={report}
              roleDialogue={sessionData.transcript?.roleDialogue || []}
              isSharedView={true}
              onSuccess={() => {}}
              onFailure={() => {}}
              onDataRefresh={() => {}}
            />
          )}
        </Box>
      </Card>
    </Container>
  );
};

export default SharedInsightsReportView;
