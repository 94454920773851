import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { verifyShareToken } from '../../services/sharedSession';
import SessionDetailPage from '../../pages/SessionDetailPage';
import { Container, Alert, CircularProgress } from '@mui/material';

const SharedSessionView = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionData, setSessionData] = useState(null);

  useEffect(() => {
    const verifyAndLoadSession = async () => {
      try {
        const response = await verifyShareToken(token);
        if (!response || !response._id) {
          throw new Error('Invalid session data received');
        }
        setSessionData(response);
      } catch (err) {
        setError(err.message || 'Failed to load shared session');
      } finally {
        setLoading(false);
      }
    };

    verifyAndLoadSession();
  }, [token]);

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!sessionData) {
    return <Navigate to="/" replace />;
  }

  return (
    <SessionDetailPage sessionId={sessionData._id} isSharedView={true} sharedToken={token} sessionData={sessionData} />
  );
};

export default SharedSessionView;
