import React from 'react';
import {
  Paper,
  Typography,
  Stack,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Tooltip,
  Chip,
} from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import DescriptionIcon from '@mui/icons-material/Description';
import InsightsIcon from '@mui/icons-material/Insights';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatIcon from '@mui/icons-material/Chat';
import Iconify from '../iconify';
import { useTheme } from '@mui/material/styles';
import WundaIconButton from './WundaIconButton';
import ShareSessionButton from './ShareSessionButton';
import WundaSpeakerRatio from './WundaSpeakerRatio';
import PropTypes from 'prop-types';

const SessionHeader = ({
  sessionData,
  isSharedView = false,
  showShareControls = false,
  handleOpenEdit = () => {},
  handleDeleteSessionConfirm = () => {},
  handleShareStatusChange = () => {},
  handleOpenListenCard = () => {},
  handleOpenTranscriptCard = () => {},
  handleOpenInsightsCard = () => {},
  handleOpenChatCard = () => {},
  openCard,
  language,
  showCards = true,
}) => {
  const theme = useTheme();
  const isMobile = window.innerWidth < 600;

  return (
    <Paper elevation={3} sx={{ p: 2, my: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h5" gutterBottom className="fs-mask">
          {sessionData.name}
        </Typography>
        <Stack direction="row" spacing={0.5}>
          {!isSharedView && (
            <>
              <WundaIconButton
                icon="eva:edit-outline"
                onClick={handleOpenEdit}
                tooltip={sessionData.demoForAll ? 'Edit (Disabled for demo)' : 'Edit session'}
                tooltipPlacement="top"
                disabled={sessionData.demoForAll}
              />
              <WundaIconButton
                icon="eva:trash-2-outline"
                onClick={handleDeleteSessionConfirm}
                tooltip={sessionData.demoForAll ? 'Delete the demo session' : 'Delete session'}
                tooltipPlacement="top"
              />
            </>
          )}
          {showShareControls && (
            <ShareSessionButton
              sessionId={sessionData._id}
              disabled={sessionData.demoForAll}
              hasActiveShare={!!sessionData.shareToken && new Date(sessionData.shareTokenExpiresAt) > new Date()}
              activeShareToken={sessionData.shareToken}
              shareTokenExpiresAt={sessionData.shareTokenExpiresAt}
              onShareStatusChange={handleShareStatusChange}
            />
          )}
        </Stack>
      </Stack>

      <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <EventIcon sx={{ fontSize: '1rem' }} />
            <Tooltip title="Session date">
              <Typography variant="body2" color="text.secondary">
                {sessionData.sessionDate
                  ? new Date(sessionData.sessionDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })
                  : ''}
              </Typography>
            </Tooltip>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              <Tooltip title="Session duration">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccessTimeIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  <Typography variant="body2" color="text.secondary">
                    {sessionData.duration} mins
                  </Typography>
                </Box>
              </Tooltip>
              <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              <Tooltip title="Transcription language">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Iconify icon="mdi-earth" sx={{ fontSize: '1rem', mr: 0.5 }} />
                  <Typography variant="body2" color="text.secondary">
                    {language && typeof language === 'object'
                      ? isMobile
                        ? language.value?.toUpperCase()
                        : language.label
                      : language || ''}
                  </Typography>
                </Box>
              </Tooltip>
              <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                {sessionData &&
                  sessionData.tags &&
                  sessionData.tags.map((tag, index) => (
                    <Chip key={index} label={tag} variant="outlined" sx={{ mr: 1, mb: 0, py: 0 }} />
                  ))}
              </Box>
            </Box>
          </Box>
        </Stack>

        {/* Render speaker role ratio component */}
        {sessionData && Object.keys(sessionData).length > 0 && <WundaSpeakerRatio data={sessionData} />}
      </Box>
      {showCards && (
        <>
          <Divider />
          <Stack direction="row" spacing={2} sx={{ mt: 2 }} justifyContent="center">
            <Card
              sx={{
                flex: 1,
                mt: 1,
                backgroundColor:
                  openCard === 'recording' ? theme.palette.secondary.main : theme.palette.background.paper,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <CardActionArea
                onClick={handleOpenListenCard}
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center">
                    <VolumeUpIcon
                      sx={{
                        fontSize: 60,
                        color: (theme) => (sessionData.audioFile ? 'inherit' : theme.palette.grey[500]),
                      }}
                    />
                    {!isMobile && (
                      <Typography
                        variant="h6"
                        sx={{ ml: 2, color: (theme) => (sessionData.audioFile ? 'inherit' : theme.palette.grey[500]) }}
                      >
                        Audio
                      </Typography>
                    )}
                  </Box>
                  <Typography variant="caption">
                    {sessionData.audioFile ? 'Listen to the audio of the session' : 'Audio recording not available'}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card
              sx={{
                flex: 1,
                mt: 1,
                backgroundColor:
                  openCard === 'transcript' ? theme.palette.secondary.main : theme.palette.background.paper,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <CardActionArea
                onClick={handleOpenTranscriptCard}
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center">
                    <DescriptionIcon
                      sx={{
                        fontSize: 60,
                        color: (theme) => (sessionData.transcript ? 'inherit' : theme.palette.grey[500]),
                      }}
                    />
                    {!isMobile && (
                      <Typography
                        variant="h6"
                        sx={{ ml: 2, color: (theme) => (sessionData.transcript ? 'inherit' : theme.palette.grey[500]) }}
                      >
                        Transcript
                      </Typography>
                    )}
                  </Box>
                  <Typography variant="caption">
                    {sessionData.transcript ? 'Read the transcription of the session' : 'Transcript not available'}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card
              sx={{
                flex: 1,
                mt: 1,
                backgroundColor:
                  openCard === 'insights' ? theme.palette.secondary.main : theme.palette.background.paper,
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <CardActionArea
                onClick={handleOpenInsightsCard}
                sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center">
                    <InsightsIcon
                      sx={{
                        fontSize: 60,
                        color: (theme) => (sessionData.insightsReports ? 'inherit' : theme.palette.grey[500]),
                      }}
                    />
                    {!isMobile && (
                      <Typography
                        variant="h6"
                        sx={{
                          ml: 2,
                          color: (theme) => (sessionData.insightsReports ? 'inherit' : theme.palette.grey[500]),
                        }}
                      >
                        Insights
                      </Typography>
                    )}
                  </Box>
                  <Typography variant="caption">
                    {sessionData.insightsReports ? 'Grow from AI insights of the session' : 'Insights not available'}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            {!isSharedView && (
              <Card
                sx={{
                  flex: 1,
                  mt: 1,
                  backgroundColor: openCard === 'chat' ? theme.palette.secondary.main : theme.palette.background.paper,
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                <CardActionArea
                  onClick={handleOpenChatCard}
                  sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box display="flex" alignItems="center">
                      <ChatIcon
                        sx={{
                          fontSize: 60,
                        }}
                      />
                      {!isMobile && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="h6" sx={{ ml: 2 }}>
                            Chat
                          </Typography>
                          <Chip
                            label="BETA"
                            size="small"
                            sx={{
                              ml: 1,
                              height: '20px',
                              backgroundColor: theme.palette.primary.main,
                              color: 'white',
                              '& .MuiChip-label': {
                                px: 1,
                                fontSize: '0.625rem',
                                fontWeight: 'bold',
                              },
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Typography variant="caption">Chat with AI about this session</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            )}
          </Stack>
        </>
      )}
    </Paper>
  );
};

SessionHeader.propTypes = {
  sessionData: PropTypes.shape({
    name: PropTypes.string,
    demoForAll: PropTypes.bool,
    _id: PropTypes.string,
    shareToken: PropTypes.string,
    shareTokenExpiresAt: PropTypes.string,
    sessionDate: PropTypes.string,
    duration: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    audioFile: PropTypes.any,
    transcript: PropTypes.any,
    insightsReports: PropTypes.any,
  }).isRequired,
  isSharedView: PropTypes.bool,
  showShareControls: PropTypes.bool,
  handleOpenEdit: PropTypes.func,
  handleDeleteSessionConfirm: PropTypes.func,
  handleShareStatusChange: PropTypes.func,
  handleOpenListenCard: PropTypes.func,
  handleOpenTranscriptCard: PropTypes.func,
  handleOpenInsightsCard: PropTypes.func,
  handleOpenChatCard: PropTypes.func,
  openCard: PropTypes.oneOf(['recording', 'transcript', 'insights', 'chat']),
  language: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ]),
  showCards: PropTypes.bool,
};

export default SessionHeader;
