import { getAuth } from 'firebase/auth';
import axios from 'axios';
import history from '../hoc/history';
import { forceLogout } from '../providers/AuthProvider';
import { logMessage } from '../utils/logger';

// Create an instance of Axios with custom configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Set the base URL for your API
  headers: {
    // Set common headers or perform other configurations
    // "Authorization": "Bearer your_token",
  },
  timeout: 180000,
});

const getBaseHostURL = (fullURL) => {
  const url = new URL(fullURL);
  return `${url.protocol}//${url.host}`; // This will return "http://localhost:3000" from your full baseURL
};

// Add a response interceptor
api.interceptors.response.use(
  (response) =>
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        forceLogout();
      }
    } else if (
      ['ECONNABORTED', 'Network Error', 'ERR_NETWORK'].includes(error.code) ||
      error.message === 'Network Error' ||
      error.code === 503
    ) {
      const event = new CustomEvent('globalError', { detail: { type: 'NO_CONNECTION' } });
      window.dispatchEvent(event);
    }
    // Reject the promise for all other errors
    return Promise.reject(error);
  }
);

export const apiRequest = async (
  method,
  url,
  {
    data = null,
    headers: customHeaders = {},
    params = null,
    useApiKey = false,
    usePublicApiKey = false,
    responseType = 'json',
    timeout = api.defaults.timeout,
    baseURL = null,
    onUploadProgress = null,
    setCancel = null,
    signal = null,
  } = {}
) => {
  const headers = { ...api.defaults.headers.common, ...customHeaders };
  const source = axios.CancelToken.source();

  // Connect abort signal to cancel token
  if (signal) {
    signal.addEventListener('abort', () => {
      source.cancel('Operation cancelled by abort signal');
    });
  }

  if (setCancel) {
    setCancel(() => {
      source.cancel('Operation cancelled by the user.');
    });
  }

  const doRequest = async (isRetry = false) => {
    try {
      const auth = getAuth();
      const { currentUser } = auth;
      if (currentUser && !useApiKey) {
        const idToken = await currentUser.getIdToken(isRetry);
        headers.Authorization = idToken;
      }

      if (useApiKey) {
        const apiKey = process.env.REACT_APP_API_KEY;
        if (apiKey) {
          headers['X-API-Key'] = apiKey;
        }
      }
      if (usePublicApiKey) {
        const publicApiKey = process.env.REACT_APP_API_KEY_PUBLIC;
        headers['x-public-api-key'] = publicApiKey;
      }

      const requestBaseURL = baseURL || api.defaults.baseURL;

      if (responseType === 'stream') {
        // For streaming, use fetch with the provided signal
        const response = await fetch(`${requestBaseURL}${url}`, {
          method,
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
          body: data ? JSON.stringify(data) : undefined,
          signal,
          keepalive: false,
        });

        if (!response.ok) {
          const error = new Error(`HTTP error! status: ${response.status}`);
          error.response = response;
          throw error;
        }

        return response;
      }

      // For non-streaming requests, use axios as before
      const requestApi = baseURL ? axios.create({ baseURL: requestBaseURL, headers: api.defaults.headers }) : api;

      const response = await requestApi.request({
        method,
        url,
        data,
        params,
        headers,
        responseType,
        timeout,
        cancelToken: source.token,
        onUploadProgress: onUploadProgress
          ? (progressEvent) => {
            try {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              onUploadProgress(percentCompleted);
            } catch (error) {
              // Handle the error here
              console.error('Error during upload:', error);
              // You can call onUploadProgress with a special value to indicate an error
              onUploadProgress(-1);
            }
          }
          : null,
      });

      return response?.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && !isRetry) {
        // If 401 is returned and this is the first request, refresh the token and try again
        logMessage('DEBUG', `apiRequest(). Got 401. Retrying with token refresh...`);
        return doRequest(true);
      }

      if (error.response && error.response.status === 401 && isRetry) {
        // If 401 is returned on the second attempt, force a logout
        logMessage('DEBUG', `apiRequest(). Got 401 even after token refresh. Logging out...`);
        await forceLogout();
      }
      // If some other error occurs or it's a second failed attempt, throw the error
      throw error;
    }
  };

  return doRequest();
};

export const healthcheck = async () => {
  try {
    // Extract the base host URL from the default baseURL
    const baseHostURL = getBaseHostURL(api.defaults.baseURL);

    // Now use baseHostURL as the baseURL for this specific request
    const response = await apiRequest('GET', '/healthcheck', { baseURL: baseHostURL });
    return response;
  } catch (error) {
    history.push({
      pathname: '/NoConnection',
      state: { key: Date.now() },
    });
    return null;
  }
};

export default api;
