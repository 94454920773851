import { apiRequest } from '../api/api';

export const generateShareToken = async ({ reportId, expiresInDays }) => {
  try {
    const response = await apiRequest('POST', `/insights-report/share/${reportId}/link`, {
      data: {
        expiresInDays,
      },
    });

    if (!response.shareToken) {
      throw new Error('No share token received from server');
    }

    return response;
  } catch (error) {
    throw new Error(`Failed to generate share token: ${error.message}`);
  }
};

export const getShareLink = (token) => {
  const baseUrl = window.location.origin;
  return `${baseUrl}/insights-report/shared/${token}`;
};

export const deleteShareToken = async (reportId) => {
  try {
    const response = await apiRequest('DELETE', `/insights-report/${reportId}/share`);
    return response;
  } catch (error) {
    throw new Error(`Failed to disable insights report sharing: ${error.message}`);
  }
};
