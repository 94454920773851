import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, CircularProgress, IconButton, Chip, Typography, CardHeader } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';
import ChatIcon from '@mui/icons-material/Chat';
import ChatMessage from './ChatMessage';
import { findClosestDialogue } from '../../utils/timestampUtils';
import WundaTranscriptPreview from '../wundamental/WundaTranscriptPreview';

const EXAMPLE_MESSAGES = [
  'What was the main topic discussed?',
  'How did the coachee feel during the session?',
  'What were the key action items?',
];

const Chat = ({
  messages,
  isLoading,
  sendMessage,
  retryLastMessage,
  stopResponse,
  roleDialogue = [],
  onTimeSelect = () => {},
}) => {
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedDialogue, setSelectedDialogue] = useState(null);
  const [dialoguePreviewOpen, setDialoguePreviewOpen] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Focus input when AI finishes responding
  useEffect(() => {
    if (!isLoading && messages.length > 0 && messages[messages.length - 1].isAI) {
      inputRef.current?.focus();
    }
  }, [isLoading, messages]);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    const message = inputRef.current.value.trim();
    if (message && !isLoading) {
      inputRef.current.value = '';
      setInputValue('');
      await sendMessage(message);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleTimeClick = (timeStr) => {
    if (!roleDialogue || !onTimeSelect) {
      console.log('Missing roleDialogue or onTimeSelect');
      return;
    }

    const dialogueItem = findClosestDialogue(timeStr, roleDialogue);

    if (dialogueItem) {
      // First show the preview
      setSelectedDialogue(dialogueItem);
      setDialoguePreviewOpen(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100vh',
      }}
    >
      <CardHeader
        avatar={
          <ChatIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: 24,
            }}
          />
        }
        title="Chat with AI"
        titleTypographyProps={{
          variant: 'h6',
          color: 'text.primary',
          fontWeight: 600,
        }}
        sx={{
          pb: 1,
          '& .MuiCardHeader-content': {
            overflow: 'hidden',
          },
        }}
      />

      {/* Messages Container */}
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          p: 2,
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: (theme) => theme.palette.grey[300],
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: (theme) => theme.palette.grey[400],
          },
        }}
      >
        {messages.length === 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, mt: 4 }}>
            <Typography variant="body2" color="text.secondary">
              Ask me anything about this coaching session
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
              {EXAMPLE_MESSAGES.map((message, index) => (
                <Chip
                  key={index}
                  label={message}
                  onClick={() => {
                    inputRef.current.value = message;
                    setInputValue(message);
                    handleSubmit();
                  }}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                      color: 'primary.contrastText',
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
        ) : (
          <>
            {messages.map((message, index) => (
              <ChatMessage
                key={index}
                message={message}
                isAI={message.isAI}
                messageIndex={index}
                onRetry={message.isAI ? (index) => retryLastMessage(index) : undefined}
                onTimeClick={handleTimeClick}
              />
            ))}
          </>
        )}
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              mb: 2,
            }}
          >
            <Box
              sx={{
                p: 2,
                backgroundColor: 'grey.50',
                borderRadius: '12px',
                borderTopLeftRadius: '4px',
              }}
            >
              <CircularProgress size={20} />
            </Box>
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>

      {/* Input Container */}
      <Box sx={{ p: 2, backgroundColor: 'background.paper' }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <TextField
            fullWidth
            placeholder="Type your message..."
            variant="outlined"
            size="small"
            inputRef={inputRef}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            disabled={isLoading}
            multiline
            maxRows={4}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
          />
          {isLoading ? (
            <IconButton
              onClick={stopResponse}
              color="primary"
              sx={{
                backgroundColor: 'background.paper',
                '&:hover': { backgroundColor: 'action.hover' },
              }}
            >
              <StopIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleSubmit}
              color="primary"
              disabled={!inputValue.trim()}
              sx={{
                backgroundColor: 'background.paper',
                '&:hover': { backgroundColor: 'action.hover' },
              }}
            >
              <SendIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      {/* Dialogue Preview Modal */}
      <WundaTranscriptPreview
        open={dialoguePreviewOpen}
        onClose={() => setDialoguePreviewOpen(false)}
        onGoToTranscript={() => {
          setDialoguePreviewOpen(false);
          onTimeSelect(selectedDialogue);
        }}
        selectedDialogue={selectedDialogue}
        roleDialogue={roleDialogue}
        onTimeSelect={onTimeSelect}
      />
    </Box>
  );
};

Chat.propTypes = {
  messages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
  retryLastMessage: PropTypes.func.isRequired,
  stopResponse: PropTypes.func.isRequired,
  roleDialogue: PropTypes.array,
  onTimeSelect: PropTypes.func,
};

export default Chat;
