import React, { useState, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Stack, Card } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { AuthContext } from '../providers/AuthProvider';
import WundaButton from '../components/wundamental/WundaButton';
import GenericSnackbar from '../components/snackbar/GenericSnackbar';
import Iconify from '../components/iconify';
import { useTheme } from '@mui/material/styles';
import WorkspaceCreationCard from '../components/workspace/WorkspaceCreationCard';
import GenericLoader from '../components/loader/GenericLoader';

const SubscriptionReturnPage = () => {
  const theme = useTheme();
  const { currentUser, refreshBalance, refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showWorkspaceCreation, setShowWorkspaceCreation] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isAutoRetrying, setIsAutoRetrying] = useState(true);
  const requestInProgressRef = useRef(false);
  const isSubscribedRef = useRef(true);
  const hasStartedSubscriptionCheck = useRef(false);

  const DELAY_MS = 5000;
  const TOTAL_TIMEOUT_MS = 30000;

  const stopRetrying = useCallback(() => {
    if (!isSubscribedRef.current) return;

    if (isAutoRetrying) {
      setIsAutoRetrying(false);
      setErrorMessage(
        'Your subscription activation is taking longer than usual. This can take up to a few minutes.'
      );
      setLoading(false);
    }
  }, [isAutoRetrying]);

  const startSubscriptionCheck = () => {
    const startTime = Date.now();

    const checkSubscription = async () => {
      if (Date.now() - startTime >= TOTAL_TIMEOUT_MS) {
        setLoading(false);
        stopRetrying();
        return;
      }

      if (requestInProgressRef.current) {
        return;
      }

      requestInProgressRef.current = true;

      try {
        const updatedUser = await refreshUser();

        if (updatedUser?.DBuser?.subscription?.isActive) {
          await refreshBalance();
          isSubscribedRef.current = true;
          setLoading(false);
          stopRetrying();
          return;
        }
      } catch (error) {
        console.error('Error during subscription activation:', error);
      } finally {
        requestInProgressRef.current = false;
      }

      setTimeout(checkSubscription, DELAY_MS);
    };

    checkSubscription();
  };

  if (!hasStartedSubscriptionCheck.current) {
    startSubscriptionCheck();
    hasStartedSubscriptionCheck.current = true;
  }

  const handleManualRetry = async () => {
    setLoading(true);
    setErrorMessage(null); // Reset error message before retry

    try {
      const updatedUser = await refreshUser();
      if (updatedUser?.DBuser?.subscription?.isActive) {
        await refreshBalance();
        isSubscribedRef.current = true;
        setLoading(false);
        return;
      }

      const errorMsg = 'Subscription is not yet active. Please try again in a few moments or contact support if the issue persists.';
      console.error(errorMsg);
      setErrorMessage(errorMsg);
      isSubscribedRef.current = false; // Ensure ref is set correctly when not active
    } catch (error) {
      const errorMsg = 'Error refreshing subscription status';
      console.error(errorMsg, error);
      setErrorMessage(errorMsg);
      isSubscribedRef.current = false; // Ensure ref is set correctly on error
    }
    setLoading(false);
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: '', severity: 'info' });
  };

  if (loading) {
    return (
      <Container
        maxWidth={false}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}
      >
        <Stack spacing={2} alignItems="center">
          <GenericLoader
            key="spinner-loader"
            variant="spinner"
            label={isAutoRetrying ? "Please wait, we're confirming your subscription..." : "Checking subscription status..."}
          />
        </Stack>
      </Container>
    );
  }

  if (errorMessage || !isSubscribedRef.current) { // Ensure error view remains
    return (
      <Container maxWidth="sm" sx={{ mt: 5 }}>
        <Card sx={{ p: 3, textAlign: 'center' }}>
          <Stack spacing={3} alignItems="center">
            <Iconify icon="mdi:alert-circle-outline" width={50} height={50} color="error.main" />
            <Typography variant="body1" color="error">
              {errorMessage || 'An unexpected error occurred. Please try again.'}
            </Typography>
            <WundaButton
              onClick={handleManualRetry}
              startIcon={<Iconify icon="mdi:refresh" />}
            >
              Check Again
            </WundaButton>
          </Stack>
        </Card>
      </Container>
    );
  }

  const isTeamSubscription = currentUser?.DBuser?.subscription?.isTeamSubscription;
  const hasWorkspace = Boolean(currentUser?.DBuser?.workspace);

  return (
    <Container maxWidth={false}>
      <Helmet>
        <title> Subscription Complete | Wundamental </title>
      </Helmet>

      <Stack alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom align="center">
          {'Subscription Successful!'}
        </Typography>
      </Stack>

      {!showWorkspaceCreation && (
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2em',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            maxWidth: '500px',
            margin: '1em auto',
          }}
        >
          <Iconify icon="material-symbols:check-circle-outline-rounded" width={50} height={50} />
          <Typography variant="h5" gutterBottom>
            You're All Set!
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            Your{' '}
            <span style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
              {currentUser?.DBuser?.subscription?.plan}
            </span>{' '}
            subscription has been activated successfully. A confirmation email will be sent to your email address.
          </Typography>

          <Stack direction="column" spacing={2} mt={2} alignItems="center">
            {isTeamSubscription && !hasWorkspace && (
              <WundaButton
                variant="contained"
                color="primary"
                onClick={() => setShowWorkspaceCreation(true)}
                startIcon={<Iconify icon="material-symbols:add-business" />}
              >
                Create Team Workspace
              </WundaButton>
            )}
            <WundaButton
              variant="outlined"
              color={isTeamSubscription && !hasWorkspace ? 'inherit' : 'primary'}
              onClick={() => navigate('/home/sessions')}
            >
              Continue to Sessions
            </WundaButton>
          </Stack>
        </Card>
      )}

      {showWorkspaceCreation && (
        <WorkspaceCreationCard
          onSuccess={() => {
            showSuccess('Workspace created successfully!');
            navigate('/home/workspace');
          }}
          onCancel={() => setShowWorkspaceCreation(false)}
        />
      )}

      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        We are here for you, might you have questions:{' '}
        <a href="mailto:support@wundamental.ai">support@wundamental.ai</a>.
      </Typography>

      <GenericSnackbar open={snackbar} autoHideDuration={6000} onClose={handleSnackbarClose} />
    </Container>
  );
};

export default SubscriptionReturnPage;
