import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { generateShareToken, deleteShareToken, getShareLink } from '../../services/sharedInsightsReport';
import WundaIconButton from './WundaIconButton';
import WundaShareDialog from './WundaShareDialog';
import GenericSnackbar from '../snackbar/GenericSnackbar';
import { analytics } from '../../firebase/firebase';
import LockIcon from '@mui/icons-material/Lock';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { apiRequest } from '../../api/api';

const getRemainingDays = (expiresAt) => {
  if (!expiresAt) return null;
  const now = new Date();
  const expiry = new Date(expiresAt);
  const diffTime = expiry - now;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const isLinkExpired = (expiresAt) => {
  if (!expiresAt) return false;
  const now = new Date();
  const expiry = new Date(expiresAt);
  return now >= expiry;
};

const ShareInsightsReportButton = ({
  report,
  onFailure,
  onShareStatusChange = () => {},
  onDataRefresh = () => {},
  onReportUpdate = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [shareLink, setShareLink] = useState(report.shareToken ? getShareLink(report.shareToken) : '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isShared, setIsShared] = useState(!!report.shareToken);
  const [expirationDays, setExpirationDays] = useState(7);

  useEffect(() => {
    if (report.shareToken) {
      setShareLink(getShareLink(report.shareToken));
      setIsShared(true);
    } else {
      setShareLink('');
      setIsShared(false);
    }
  }, [report.shareToken]);

  const handleClose = () => {
    setOpen(false);
  };

  const showSuccess = (message) => {
    setSnackbar({ open: true, message, severity: 'success' });
  };

  const handleShare = () => {
    setOpen(true);
  };

  const handleEmailShare = async (email, expirationDays) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiRequest('POST', `/insights-report/share/${report._id}/email`, {
        data: {
          email,
          expiresInDays: expirationDays,
        },
      });
      logEvent(analytics, 'share_insights_report', {
        reportId: report._id,
        method: 'email',
      });
      onReportUpdate({
        ...report,
        shareHistory: response.shareHistory,
        shareToken: response.shareToken,
      });
      onShareStatusChange();
      setSnackbar({
        open: true,
        message: `Report sent successfully to ${email}`,
        severity: 'success',
      });
      handleClose();
    } catch (error) {
      setError(error.message);
      console.error('Error sharing report via email:', error);
      onFailure('share', `Error sharing report via email! ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateNewLink = async (days) => {
    setLoading(true);
    setError(null);
    try {
      const response = await generateShareToken({
        reportId: report._id,
        expiresInDays: days,
      });
      const link = getShareLink(response.shareToken);
      setShareLink(link);
      setExpirationDays(days);
      setIsShared(true);
      logEvent(analytics, 'share_insights_report', {
        reportId: report._id,
        method: 'link',
      });
      showSuccess('Successfully generated share link!');
      onReportUpdate({
        ...report,
        shareToken: response.shareToken,
        shareTokenExpiresAt: response.shareTokenExpiresAt,
        shareHistory: response.shareHistory,
      });
      onShareStatusChange();
      onDataRefresh();
    } catch (error) {
      setError(error.message);
      console.error('Error generating share link:', error);
      onFailure('share', `Error generating share link! ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleUnshare = async () => {
    setLoading(true);
    setError(null);
    try {
      await deleteShareToken(report._id);
      setShareLink('');
      setIsShared(false);
      showSuccess('Successfully removed sharing!');
      onReportUpdate({
        ...report,
        shareToken: null,
        shareTokenExpiresAt: null,
        shareHistory: [],
      });
      onShareStatusChange();
      onDataRefresh();
      handleClose();
    } catch (error) {
      setError(error.message);
      console.error('Error removing share:', error);
      onFailure('unshare', error.message);
    } finally {
      setLoading(false);
    }
  };

  const linkShareInfo = [
    {
      icon: <LockIcon fontSize="small" />,
      primary: 'Secure Access',
      secondary: 'Only people with the link can access this report',
    },
    {
      icon: <AccessTimeIcon fontSize="small" />,
      primary: 'Limited Time',
      secondary: report.shareToken
        ? report.shareTokenExpiresAt
          ? `Link expires in ${getRemainingDays(report.shareTokenExpiresAt)} days`
          : 'Link never expires'
        : 'Link expires after 7 days',
    },
  ];

  return (
    <>
      <span>
        <Badge
          color="primary"
          variant="dot"
          invisible={!isShared}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <WundaIconButton
            icon="eva:share-outline"
            onClick={handleShare}
            tooltip={report.shareToken ? 'Report is currently shared' : 'Share report'}
            tooltipPlacement="top"
          />
        </Badge>
      </span>

      <WundaShareDialog
        open={open}
        onClose={handleClose}
        title="Share Report"
        showLinkShare={true}
        showEmailShare={true}
        currentLink={shareLink}
        isLoading={loading}
        error={error}
        shareTokenExpiresAt={report.shareTokenExpiresAt}
        onGenerateLink={handleGenerateNewLink}
        onEmailShare={handleEmailShare}
        linkShareProps={{
          onDisableShare: handleUnshare,
          showDisableShare: !!shareLink,
          infoList: linkShareInfo,
          generateNewLinkLabel: shareLink ? 'Generate New Link' : 'Generate Link',
          disableShareLabel: 'Disable Sharing',
          isExpired: isLinkExpired(report.shareTokenExpiresAt),
          expiredMessage: 'This share link has expired. Please generate a new link.',
          expirationDays,
          onExpirationChange: (days) => setExpirationDays(days),
        }}
        emailShareProps={{
          reportId: report._id,
          shareHistoryCount: report.shareHistory?.length || 0,
        }}
      />

      <GenericSnackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ open: false, message: '', severity: 'info' })}
      />
    </>
  );
};

ShareInsightsReportButton.propTypes = {
  report: PropTypes.object.isRequired,
  onFailure: PropTypes.func.isRequired,
  onShareStatusChange: PropTypes.func,
  onDataRefresh: PropTypes.func,
  onReportUpdate: PropTypes.func,
};

export default ShareInsightsReportButton;
